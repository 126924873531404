import * as React from "react"
import Sitemaplinks from "../components/Landing/sitemaplinks"
import Layout from "../components/layout"




const sitemap = () => (
  <Layout>
    <Sitemaplinks />
  </Layout>
)

export default sitemap
