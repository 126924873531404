import { Link } from 'gatsby'
import React from 'react'




class Sitemaplinks extends React.Component {

  render() {
    return (
      <footer className="footerbottombg sitemapage">
        <div className="sitebg">
            <h1>SITE MAP</h1>
            <p>The planning of a website</p>
        </div>
        <section className="footer-gray-bg fnt-reg site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-8 col-sm-12 col-12 listrightbox footlistbg">
                <h3 className="h3">Exchange Software</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/white-label-crypto-exchange-software/">White Label Crypto Exchange</Link></li>
                  <li><Link to="https://www.coinsclone.com/otc-crypto-exchange-software/">OTC Crypto Exchange Software</Link></li>
                  <li><Link to="https://www.coinsclone.com/p2p-crypto-exchange-software/">P2P Crypto Exchange Software</Link></li>
                </ul>
                <h3 className="h3 company-hd mt-3">Wallet Clone</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/trustwallet-clone-app-development/">Trust Wallet Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/metamask-wallet-clone-script/">Metamask Clone Script</Link></li>
                </ul>
                <h3 className="h3 company-hd mt-3">Company</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/">Home</Link></li>
                  <li><a href="https://www.coinsclone.com/blog/">Blog</a></li>
                  <li><Link to="https://www.coinsclone.com/about-us/">About Us</Link></li>
                  <li><Link to="https://www.coinsclone.com/contact-us/">Contact Us</Link></li>
                  <li><Link to="https://www.coinsclone.com/terms-and-conditions/">Terms & Conditions</Link></li>
                  <li><Link to="https://www.coinsclone.com/privacy-policy/">Privacy Policy</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12 footlistbg company">
                <h3 className="h3">Exchange Clone</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/binance-clone-script/">Binance Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/coinbase-clone-script/">Coinbase Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/localbitcoins-clone-script/">Localbitcoins Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/paxful-clone-script/">Paxful Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/remitano-clone-script/">Remitano Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/wazirx-clone-script/">Wazirx Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/kraken-clone-script/">Kraken Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/kucoin-clone-script/">Kucoin Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/huobi-clone-script/">Huobi Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/poloniex-clone-script/">Poloniex Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/bithumb-clone-script/">Bithumb Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/bitfinex-clone-script/">Bitfinex Clone Script</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-8 col-sm-12 col-12 footlistbg exscript">
                <h3 className="h3">DeFi</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/pancakeswap-clone-script/">PancakeSwap Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/uniswap-clone-script/">UniSwap Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/sushiswap-clone-script/">SushiSwap clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/bakeryswap-clone-script/">BakerySwap Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/1inch-exchange-clone-script/">1Inch Exchange Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/decentralized-exchange-development-company/">Decentalized Exchange Development Company</Link></li>
                  <li><Link to="https://www.coinsclone.com/defi-wallet-development-company/">DeFi Wallet Development Company</Link></li>
                  <li><Link to="https://www.coinsclone.com/defi-crowdfunding-platform-development/">DeFi Crowdfunding Platform Development</Link></li>
                  <li><Link to="https://www.coinsclone.com/defi-development-company/">DeFi Development Company</Link></li>
                </ul>
                <h3 className="h3 company-hd mt-3">Payment Gateway Clone</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/bitpay-clone-script/">Bitpay Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/coinpayments-clone-script/">Coinpayments Clone Script</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12 footlistbg clnscript nft-row">
                <h3 className="h3">NFT</h3>
                <ul className="foot-list">
                  <li><Link to="https://www.coinsclone.com/opensea-clone-script/">OpenSea Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/rarible-clone-script/">Rarible Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/foundation-clone-script/">Foundation Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/superrare-clone-script/">SuperRare Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/enjin-clone-script/">Enjin Clone Script</Link></li>
                  <li><Link to="https://www.coinsclone.com/nft-marketplace-development/">NFT Marketplace Development</Link></li>
                  <li><Link to="https://www.coinsclone.com/white-label-nft-marketplace/">White Label NFT Marketplace</Link></li>
                  <li><Link to="https://www.coinsclone.com/nft-exchange-development/">NFT Exchange Development</Link></li>
                  <li><Link to="https://www.coinsclone.com/nft-token-development/">NFT Token Development</Link></li>
                  <li><Link to="https://www.coinsclone.com/nft-lending-platform-development/">NFT Lending Platform Development</Link></li>
                </ul>
                
              </div>
            </div>
          </div>
        </section>
      </footer>
    );
  }
}

export default Sitemaplinks